"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contract = void 0;
const michelson_encoder_1 = require("@taquito/michelson-encoder");
const semantic_1 = require("./semantic");
class Contract {
    constructor(sdk, contractAddress) {
        this.sdk = sdk;
        this.contractAddress = contractAddress;
    }
    getStorage(block = 'head') {
        return __awaiter(this, void 0, void 0, function* () {
            const script = yield this.sdk.rpc.getScript(this.contractAddress, { block });
            const contractSchema = michelson_encoder_1.Schema.fromRPCResponse({ script: script });
            return contractSchema.Execute(script.storage, (0, semantic_1.smartContractAbstractionSemantic)(this.sdk.contract));
        });
    }
    /**
     * Get the latest finalized snapshot.
     */
    latestSnapshot() {
        return __awaiter(this, void 0, void 0, function* () {
            const storage = yield this.getStorage();
            const latestBlockLevel = storage.history.pop();
            if (!latestBlockLevel) {
                throw new Error('No snapshots yet.');
            }
            const submissions = (yield storage.state_root.get(latestBlockLevel)) || [];
            let merkleRoot = '';
            const distinct = {};
            for (const submission of submissions === null || submissions === void 0 ? void 0 : submissions.values()) {
                distinct[submission] || (distinct[submission] = 0);
                distinct[submission] += 1;
                if (!distinct[merkleRoot] || distinct[merkleRoot] < distinct[submission]) {
                    merkleRoot = submission;
                }
            }
            return {
                block_number: latestBlockLevel.toNumber(),
                merkle_root: '0x' + merkleRoot,
            };
        });
    }
    /**
     * Submit the state root associated with a given ethereum block.
     */
    submitBlockStateRoot(block_level, state_root) {
        return __awaiter(this, void 0, void 0, function* () {
            const contract = yield this.sdk.contract.at(this.contractAddress);
            return contract.methods.submit_block_state_root(block_level, state_root);
        });
    }
}
exports.Contract = Contract;
