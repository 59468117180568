import { Network } from './context/AppContext';

const Constants = {
    network: 'custom',
    rpc: 'https://tezos-ghostnet-node-1.diamond.papers.tech',
    [Network.Ethereum]: {
        network: 'custom',
        ethChainId: '0x5',
        rpc: 'https://tezos-ghostnet-node-1.diamond.papers.tech',
        tezos_bridge: 'KT1AyXoM57cyXv8yZLn4nBNzVSmCJKMtuEdf',
        tezos_crowdfunding: 'KT1RUuZ1D8UZdY59cLjbC2GCCqP4HUvDeiix',
        tezos_validator: 'KT1QfWxeYitE4NXvC4caBJpwimzxZygqPJ9F',
        tezos_state_aggregator: 'KT1B8F7BhA2EeGQvPFpaVStd99ZDSzwrAcBL',
        evm_bridge: '0x945BEeA0C9C8052764e8Ef54a571386Cd1027BF1',
        evm_crowdfunding: '0x79a20860c063a70EB483f5b6fb70b9CaDb022729',
        evm_validator: '0xE258F751a56343b722263cbF9Fdf3e71F1815cAA',
        tokenSymbol: 'GoerliETH',
        etherscan: 'https://goerli.etherscan.io',
        tzkt: 'https://ghostnet.tzkt.io',
        rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
    },
    [Network.Polygon]: {
        network: 'custom',
        ethChainId: '0x13881',
        rpc: 'https://tezos-ghostnet-node-1.diamond.papers.tech',
        tezos_bridge: 'KT1HEDc9u625CvuBnVcW4rGwj3y7un5X4FQn',
        tezos_crowdfunding: 'KT1BeAbWcQtdaLzFS4XRgCThHbY6Ub4xso1M',
        tezos_validator: 'KT1Gf13zVZuQAQF1zfMVYWxTye3ghqK9HQLY',
        tezos_state_aggregator: 'KT1B8F7BhA2EeGQvPFpaVStd99ZDSzwrAcBL',
        evm_bridge: '0xB709A925CED4b900bFF971C2c88AFDD1767aEF9F',
        evm_crowdfunding: '0xD392928Bac5995c2bE96fE75583B2C3F250d9aAd',
        evm_validator: '0x6a8fE13d57998834feA5181607a8a3f4Cd0B42d4',
        tokenSymbol: 'UNKNOWN',
        etherscan: 'https://mumbai.polygonscan.com/',
        tzkt: 'https://ghostnet.tzkt.io',
        rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'],
    },
    [Network.BSC]: {
        network: 'custom',
        ethChainId: '0x61',
        rpc: 'https://tezos-ghostnet-node-1.diamond.papers.tech',
        tezos_bridge: 'KT1Doki6jfUwmanSXwgHPa7gL4N8BWrSJY12',
        tezos_crowdfunding: 'KT1AqQjU7XKDgrr2iiqgvHkvZ1ee4ytBm143',
        tezos_validator: 'KT1T1nKDTWD4XBR1ABv7rNJRupwNViD5JR7j',
        tezos_state_aggregator: 'KT1B8F7BhA2EeGQvPFpaVStd99ZDSzwrAcBL',
        evm_bridge: '0xB18E503FF95Ff3F8CA212b1F9a555Aff7D34F7eb',
        evm_crowdfunding: '0x4d25ff2F625D84016c6ACf245B664d84810Ad445',
        evm_validator: '0xd0ca0c73707727d9b758bBeFFc6cDcC1Dba9De39',
        tokenSymbol: 'UNKNOWN',
        etherscan: 'https://testnet.bscscan.com/',
        tzkt: 'https://ghostnet.tzkt.io',
        rpcUrls: [
            'https://sleek-powerful-aura.bsc-testnet.discover.quiknode.pro/51526b94c99d8f727fd1492e6cba4e6c6d1143dd',
            'https://bsc-testnet.nodereal.io/v1/288880000e6c4fdaa094617ee0c710d3',
        ],
    },
    [Network.Avalanche]: {
        network: 'custom',
        ethChainId: '0xa869',
        rpc: 'https://tezos-ghostnet-node-1.diamond.papers.tech',
        tezos_bridge: 'KT1G6ApnJu8dANc9BSNpMM6vqfrFYgekidHy',
        tezos_crowdfunding: 'KT19bqbqBiJXsiFP2yiV2MoRH1txhhmEoQxN',
        tezos_validator: 'KT1PQJoEAmu8H2hhCXW9bRUiu5JdRjrppkXL',
        tezos_state_aggregator: 'KT1B8F7BhA2EeGQvPFpaVStd99ZDSzwrAcBL',
        evm_bridge: '0x59896cf96A18C6882A445E4A5d2467b32f31C0a1',
        evm_crowdfunding: '0x78E049f016Ae89c22606B2A7662e1491537ef532',
        evm_validator: '0x8687ddcC439DbB3f479D035902DD2a4c19a3f259',
        tokenSymbol: 'UNKNOWN',
        etherscan: 'https://testnet.snowtrace.io/',
        tzkt: 'https://ghostnet.tzkt.io',
        rpcUrls: [],
    },
};

export default Constants;
