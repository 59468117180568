"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProofGenerator = void 0;
const ethers_1 = require("ethers");
// function buildHeaderBytes(block: any) {
//     const fields = [
//         toBuffer(block.parentHash),
//         toBuffer(block.sha3Uncles),
//         toBuffer(block.miner),
//         toBuffer(block.stateRoot),
//         toBuffer(block.transactionsRoot),
//         toBuffer(block.receiptsRoot),
//         toBuffer(block.logsBloom),
//         toBuffer(ethers.utils.hexlify(block.difficulty)),
//         toBuffer(ethers.utils.hexlify(block.number)),
//         toBuffer(ethers.utils.hexlify(block.gasLimit)),
//         toBuffer(ethers.utils.hexlify(block.gasUsed)),
//         toBuffer(ethers.utils.hexlify(block.timestamp)),
//         toBuffer(block.extraData),
//         toBuffer(block.mixHash),
//         toBuffer(block.nonce),
//         toBuffer(ethers.utils.hexlify(block.baseFeePerGas)),
//     ];
//     return Buffer.from(ethers.utils.RLP.encode(fields)).toString('hex');
// }
class ProofGenerator {
    constructor(provider) {
        this.provider = provider;
    }
    generateStorageProof(contract, slots, block_number) {
        return __awaiter(this, void 0, void 0, function* () {
            block_number || (block_number = yield this.provider.getBlockNumber());
            const proof = yield this.provider.send('eth_getProof', [contract, slots, '0x' + block_number.toString(16)]);
            const account_proof_rlp = ethers_1.ethers.utils.RLP.encode(proof.accountProof.map((node) => ethers_1.ethers.utils.RLP.decode(node)));
            const storage_proofs_rlp = slots.map((_, i) => ethers_1.ethers.utils.RLP.encode(proof.storageProof[i].proof.map((node) => ethers_1.ethers.utils.RLP.decode(node))));
            return {
                block_number,
                account_proof_rlp,
                storage_proofs_rlp,
            };
        });
    }
}
exports.ProofGenerator = ProofGenerator;
