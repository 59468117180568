"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unpackAddress = exports.packAddress = exports.utf8ToHex = exports.unpack = exports.pack = exports.MichelsonType = void 0;
const michel_codec_1 = require("@taquito/michel-codec");
var MichelsonType;
(function (MichelsonType) {
    MichelsonType["nat"] = "nat";
    MichelsonType["int"] = "int";
    MichelsonType["address"] = "address";
})(MichelsonType = exports.MichelsonType || (exports.MichelsonType = {}));
function pack(value, type) {
    switch (type) {
        case MichelsonType.nat:
        case MichelsonType.int:
            return (0, michel_codec_1.packDataBytes)({ int: String(value) }, { prim: type }).bytes;
        case MichelsonType.address:
            return (0, michel_codec_1.packDataBytes)({ string: String(value) }, { prim: type }).bytes;
    }
}
exports.pack = pack;
function unpack(value, type) {
    const data = (0, michel_codec_1.unpackDataBytes)({ bytes: value.startsWith('0x') ? value.slice(2) : value }, { prim: type });
    switch (type) {
        case MichelsonType.nat:
        case MichelsonType.int:
            return data.int;
        case MichelsonType.address:
            return data.string;
    }
}
exports.unpack = unpack;
function utf8ToHex(text) {
    return Array.from(text).reduce((p, c) => p + c.charCodeAt(0).toString(16), '');
}
exports.utf8ToHex = utf8ToHex;
/**
 * Pack a tezos address.
 * @param b58Address Base58 encoded address
 * @returns Packed tezos address
 */
function packAddress(b58Address) {
    return pack(b58Address, MichelsonType.address);
}
exports.packAddress = packAddress;
/**
 * Unpack a tezos address.
 * @param bytes Packed tezos address
 * @returns Base58 encoded address
 */
function unpackAddress(bytes) {
    return unpack(bytes, MichelsonType.address);
}
exports.unpackAddress = unpackAddress;
